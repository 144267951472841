@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes wave {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0) translateX(0);
    }

    50% {
        transform: translateY(-20px) translateX(10px);
    }
}

.animate-gradient {
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;
}

.wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%234A90E2'/%3E%3C/svg%3E");
    background-position: 0 bottom;
    background-repeat: repeat-x;
    background-size: 50% 100px;
    animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.wave1 {
    animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    opacity: 0.15;
}

.wave2 {
    animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
    opacity: 0.1;
}

.wave3 {
    animation: wave 18s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.25s infinite;
    opacity: 0.05;
}

.animate-float {
    animation: float 5s ease-in-out infinite;
}